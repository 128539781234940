import { styled } from "styled-components";

const FooterWrapper = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
  background-color: ${({ theme }) => theme.color.secondary.main};
`;

const Footer = styled.footer`
  width: 100%;
  max-width: 1440px;
  color: ${({ theme }) => theme.color.primary.main};

  @media screen and (max-width: 479px) {
    display: flex;
    flex-direction: column;
    padding: 16px;
    gap: 16px;
  }

  @media screen and (min-width: 480px) and (max-width: 991px) {
    display: flex;
    flex-wrap: wrap;
  }

  @media screen and (min-width: 992px) {
    display: grid;
    grid-template-columns: 1fr 1fr;
    padding: 48px 16px;
  }
`;

const First = styled.div`
  padding: 16px;
  gap: 16px;

  a {
    color: ${({ theme }) => theme.color.primary.light};
    text-decoration: none;
  }

  img {
    width: 100%;
    max-width: 150px;
    grid-area: image;
  }

  @media screen and (min-width: 480px) and (max-width: 639px) {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
  }

  @media screen and (min-width: 640px) {
    display: grid;
    grid-template-areas: "image image" "company adddress" "contact contact";
  }
`;
const Second = styled.div`
  @media screen and (min-width: 480px) and (max-width: 991px) {
    flex-basis: calc(100% - 32px);
    padding: 16px;
  }

  @media screen and (min-width: 992px) {
    min-height: 300px;
  }
`;

const Company = styled.div`
  margin-bottom: 16px;
  p {
    padding: 0;
    margin: 0;
  }

  @media screen and (min-width: 640px) {
    grid-area: company;
  }
`;

const Address = styled.div`
  p {
    padding: 0;
    margin: 0;
    text-transform: uppercase;
  }

  @media screen and (min-width: 640px) {
    grid-area: adddress;
  }
`;

const Contact = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin: 16px 0;

  @media screen and (min-width: 640px) {
    grid-area: contact;
  }

  @media screen and (min-width: 992px) {
    justify-content: flex-start;
    gap: 16px;
  }
`;

const IconnedTitle = styled.div`
  display: flex;
  gap: 8px;
  align-items: center;
  margin-bottom: 4px;
`;

const IconWrapper = styled.span`
  display: inline-flex;
  align-items: center;
  padding: 4px;
  height: 24px;
  width: 24px;
`;

export {
  FooterWrapper,
  Footer,
  First,
  Second,
  Contact,
  IconnedTitle,
  IconWrapper,
  Company,
  Address,
};
