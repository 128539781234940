import React from "react";
import Header from "./components/Header";
import AppProvider from "./contexts/AppContext";
import Services from "./sections/Services";
import Footer from "./sections/Footer";
import View3d from "./components/View3d";
import Hero from "./components/Hero";
import Places from "./components/Places";
import Offer from "./components/Offer";
import Seo from "./components/Seo/Seo";

function App() {
  return (
    <AppProvider>
      <Seo />
      <Header />
      <Hero />
      <Services />
      <Offer />
      <Places />
      <View3d />
      <Footer />
    </AppProvider>
  );
}

export default App;
