import { ReactNode, useMemo, useState } from "react";

import * as Styled from "./Services.styled";
import { servicesList } from "./Services.mock";

const Services = () => {
  const [item, setItem] = useState(servicesList[0].id);
  const [readMore, setReadMore] = useState(false);

  const { items, nav } = useMemo(
    () =>
      servicesList.reduce<{
        nav: Array<{ id: string; title: string; icon: ReactNode }>;
        items: Record<string, { description: string; content: ReactNode }>;
      }>(
        (a, b) => {
          return {
            nav: [...a.nav, { id: b.id, title: b.title, icon: b.icon }],
            items: {
              ...a.items,
              [b.id]: { description: b.description, content: b.content },
            },
          };
        },
        { nav: [], items: {} }
      ),
    []
  );

  const selectedItem = useMemo(() => items[item], [item, items]);

  const changeItem = (name: string) => {

    if(readMore){
      setReadMore(false);
      setTimeout(()=>{
        setItem(name);
      }, 500)
    }else {
      setItem(name);
    }
  };

  const toggleReadMore = () => {
    setReadMore((p) => !p);
  };

  return (
    <Styled.Services>
      <Styled.Ul>
        {nav.map((e, idx) => (
          <li key={idx}>
            <Styled.Button onClick={() => changeItem(e.id)}>
              {e.title}
            </Styled.Button>
          </li>
        ))}
      </Styled.Ul>
      <div>
        <Styled.Service active={readMore}>
          <Styled.Paragraph>
            {selectedItem.description}
            <Styled.ReadMoreBtn onClick={toggleReadMore}>
              Pokaż {readMore ? "mniej" : "więcej"}
            </Styled.ReadMoreBtn>
          </Styled.Paragraph>
          <Styled.Content>
            {selectedItem.content}
            <Styled.ReadMoreBtn onClick={toggleReadMore}>
              Pokaż {readMore ? "mniej" : "więcej"}
            </Styled.ReadMoreBtn>
          </Styled.Content>
        </Styled.Service>
      </div>
    </Styled.Services>
  );
};

export default Services;
