import Map from "../../components/Map";
import { contacts } from "./Footer.mock";
import * as Styled from "./Footer.styled";
import IconnedTitle from "./IconnedTitle";

const Footer = () => {
  return (
    <Styled.FooterWrapper>
      <Styled.Footer>
        <Styled.First>
          <img alt='logo biura rachunkowego MB' src="/new-images/logo_self.webp" />
          <Styled.Company>
            <p>MB BIURO RACHUNKOWE SP. Z O.O.</p>
            <p>UL. CEDROWA 7</p>
            <p>62-020 ZALASEWO</p>
            <p>NIP: 777 33 00 392</p>
          </Styled.Company>
          <Styled.Address>
            <p>biuro Swarzędz:</p>
            <p>MB BIURO RACHUNKOWE SP. Z O.O.</p>
            <p>UL. ŻEROMSKIEGO 22</p>
            <p>62-020 SWARZĘDZ</p>
            <br />
            <p>biuro Kostrzyn:</p>
            <p>UL. Słowackiego 5/12</p>
            <p>62-025 Kostrzyn</p>
          </Styled.Address>
          <Styled.Contact>
            {contacts.map((contact) => (
              <IconnedTitle
                key={contact.id}
                icon={contact.icon}
                title={contact.title}
                color={contact.color}
                url={contact.url}
              />
            ))}
          </Styled.Contact>
        </Styled.First>
        <Styled.Second>
          <Map />
        </Styled.Second>
      </Styled.Footer>
    </Styled.FooterWrapper>
  );
};

export default Footer;
