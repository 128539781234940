export const offer = [
  {
    title: "Usługi księgowe",
    image: "image_01.jpg",
    items: [
      <li>
        prowadzenie pełnej księgowości, prowadzenie ksiąg handlowych,
        podatkowych ksiąg przychodów i rozchodów, ryczałtów,
      </li>,
      <li>
        prowadzenie ewidencji środków trwałych oraz innych ewidencji wymaganych
        przez prawo
      </li>,
      <li>
        sporządzanie deklaracji z tytułu podatku dochodowego od osób prawnych i
        fizycznych, oraz VAT
      </li>,
      <li>organizacje zasad przepływu dokumentacji księgowej</li>,
      <li>
        sporządzanie, na życzenie Klienta, raportów i sprawozdań umożliwiających
        Klientowi bieżącą kontrolę i analizę sytuacji finansowej jego
        przedsiębiorstwa
      </li>,
      <li>
        przygotowywanie rocznych deklaracji podatku dochodowego od osób
        fizycznych oraz rocznych deklaracji ZUS
      </li>,
      <li>sporządzaniem innych obowiązkowych sprawozdań (GUS, NBP itp.)</li>,
      <li>wyprowadzanie zaległości i sprawdzanie poprzednich księgowań</li>,
    ],
  },
  {
    title: "Kadry i płace",
    image: "image_02.jpg",
    items: [
      <li>ustalanie i naliczenie wynagrodzeń oraz sporządzanie list płac</li>,
      <li>
        obliczanie wszystkich obciążeń związanych z wypłatą wynagrodzeń (ZUS,
        US, PFRON itp.)
      </li>,
      <li>
        wystawianie zaświadczeń o osiągniętych przez pracowników dochodach
      </li>,
      <li>
        rozliczanie wynagrodzeń osób wykonujących prace na podstawie umów
        cywilnoprawnych
      </li>,
      <li>prowadzenie dokumentacji kadrowej</li>,
    ],
  },
  {
    title: "Pozostała oferta",
    image: "image_03.jpg",
    items: [
      <li>reprezentowanie w urzędach skarbowych, ZUS, KRS i sądach</li>,
      <li>
        pomoc w zakładaniu firm, (również dla obcokrajowców) oraz w ich
        likwidacji
      </li>,
      <li>obsługa prawna</li>,
      <li>wnioski kredytowe i biznesplany</li>,
      <li>pomoc w windykacji należności</li>,
      <li>inne usługi według indywidualnych ustaleń z klientem</li>,
    ],
  },
];
