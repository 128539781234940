import React from "react";
import * as Styled from "./Map.styles";

const Map = () => {
  return (
    <Styled.Map>
      <iframe
        title="Mapa wskazująca na adres biura MB"
        className="embed-responsive-item"
        src="//maps.google.com/maps?output=embed&amp;q=Żeromskiego 22, Swarzędz&amp;t=m&amp;hl=English"
      ></iframe>
    </Styled.Map>
  );
};

export default Map;
