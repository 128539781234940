import styled, { css } from "styled-components";

const HeaderWrapper = styled.div<{ isTop: boolean }>`
  width: 100%;
  display: flex;
  justify-content: center;
  transition: background-color 0.2s ease-in-out;
  background-color: ${({ isTop }) =>
    isTop ? "rgba(255, 255, 255, 0.6)" : "rgba(255, 255, 255, 0.9)"};
  position: fixed;
  top: 0;
  left: 0;

  img {
    height: calc(100% - 16px);
    width: auto;
  }

  @media screen and (max-width: 991px) {
    height: 64px;
  }

  @media screen and (min-width: 992px) {
    height: 80px;
  }
`;

const Header = styled.header`
  width: 100%;
  max-width: 1440px;
  color: ${({ theme }) => theme.color.secondary.main};
  display: flex;
  align-items: center;
`;

export { HeaderWrapper, Header };
