import { styled } from "styled-components";

const Offers = styled.section`
  max-width: 1440px;
  margin: 0 auto;
  display: flex;
  flex-direction: column;
  gap: 24px;

  @media screen and (max-width: 991px) {
  }

  @media screen and (min-width: 992px) {
    padding: 64px 0;
    gap: 64px;
  }
`;

const Offer = styled.div`
  display: grid;

  img {
    width: 100%;
    height: 100%;
    object-fit: cover;
    grid-area: image;
  }

  @media screen and (max-width: 991px) {
    grid-template-rows: 200px 1fr;
    grid-template-areas: "image" "content";
  }

  @media screen and (min-width: 992px) and (max-width: 1440px) {
    grid-template-columns: 1fr 3fr;
    grid-template-areas: "image content";
    gap: 24px;

    &:nth-child(odd) {
      grid-template-columns: 3fr 1fr;
      grid-template-areas: "content image";
    }
  }

  @media screen and (min-width: 1441px) {
    grid-template-columns: 2fr 3fr;
    grid-template-areas: "image content";
    gap: 24px;

    &:nth-child(odd) {
    grid-template-columns: 3fr 2fr;
      grid-template-areas: "content image";
    }
  }
`;
const Content = styled.div`
  grid-area: content;

  @media screen and (max-width: 991px) {
    padding: 16px;
  }
  @media screen and (min-width: 992px) {
    padding: 48px 32px;
  }
`;

const List = styled.ul`
  list-style: none;
  padding: 0;

  li {
    padding: 4px 0;
    display: flex;

    &:before {
      content: "✓";
      display: inline-block;
      margin-right: 8px;
    }

    @media screen and (max-width: 991px) {
      font-size: 16px;
    }

    @media screen and (min-width: 992px) {
      font-size: 16px;
    }
  }
`;

export { Offers, List, Offer, Content };
