import React, { ReactNode, useContext, useState } from "react";
import { ThemeProvider } from "styled-components";
import { light } from "../themes/light";
import { dark } from "../themes/dark";

const theme = {
  light,
  dark,
};

const defaultValues = {
  theme: "light",
  changeTheme: () => {},
};

const AppContext = React.createContext(defaultValues);

export const useApp = () => {
  const data = useContext(AppContext);

  return data;
};

const AppProvider = ({ children }: { children: ReactNode }) => {
  const [selectedTheme, setSelectedTheme] = useState<"light" | "dark">("light");

  const changeTheme = () => {
    setSelectedTheme((p) => (p === "dark" ? "light" : "dark"));
  };

  return (
    <AppContext.Provider value={{ theme: selectedTheme, changeTheme }}>
      <ThemeProvider theme={theme[selectedTheme]}>{children}</ThemeProvider>
    </AppContext.Provider>
  );
};

export default AppProvider;
