import { IoCalendarOutline } from "react-icons/io5";
import { BsPersonExclamation, BsCalculator } from "react-icons/bs";

export const servicesList = [
  {
    id: "service_01",
    title: "Księgowość",
    icon: <BsCalculator />,
    description:
      " Nasza Firma oferuje kompleksową obsługę firm w zakresie niezbędnym do prawidłowego rozliczania się z urzędem skarbowym z tytułu prowadzonej działalności gospodarczej. Dekretacja i księgowanie, ewidencja środków trwałych, amortyzacja, rejestr zakupów, sprzedaży, deklaracje VAT , zaliczki na podatek dochodowy",
    content: (
      <>
        <h4>
          W ramach usług księgowych Naszego biura rachunkowego mieści się m.in.
          stałe i okresowe prowadzenie na rzecz zleceniodawców:
        </h4>
        <ul>
          <li>
            ksiąg rachunkowych (handlowych)
            <ul>
              <li>zakładanie ksiąg rachunkowych,</li>
              <li>opracowanie zasad polityki rachunkowości,</li>
              <li>prowadzenie ksiąg rachunkowych,</li>
              <li>
                prowadzenie odrębnych ewidencji dla celów podatku od towarów i
                usług (VAT),
              </li>
              <li>
                zakładanie i aktualizację ewidencji środków trwałych oraz
                wartości niematerialnych i prawnych,
              </li>
              <li>
                sporządzanie deklaracji oraz zeznań podatkowych (PIT, CIT),
              </li>
              <li>
                sporządzanie informacji podsumowujących o transakcjach
                wewnątrzwspólnotowych,
              </li>
              <li>sporządzanie sprawozdań finansowych,</li>
              <li>sporządzanie sprawozdań dla celów statystycznych,</li>
              <li>reprezentowanie klientów w kontaktach z US i ZUS.</li>
            </ul>
          </li>
          <li>
            podatkowej księgi przychodów i rozchodów
            <ul>
              <li> prowadzenie podatkowej księgi przychodów i rozchodów,</li>
              <li>
                prowadzenie odrębnych ewidencji dla celów podatku od towarów i
                usług (VAT),
              </li>
              <li>
                prowadzenie ewidencji środków trwałych oraz wartości
                niematerialnych i prawnych,
              </li>
              <li> prowadzenie ewidencji wyposażenia,</li>
              <li>
                sporządzanie deklaracji podatkowych oraz zeznań podatkowych,
              </li>
              <li>
                sporządzanie informacji podsumowujących o transakcjach
                wewnątrzwspólnotowych,
              </li>
              <li>
                ustalanie należnych zaliczek w zakresie podatku dochodowego,
              </li>
              <li>
                pomoc w ustaleniu optymalnej formy opodatkowania dochodów,
              </li>
              <li> reprezentowanie klientów w kontaktach z US i ZUS.</li>
            </ul>
          </li>
          <li>
            ewidencji przychodów (ryczałt ewidencjonowany)
            <ul>
              <li>prowadzenie ewidencji przychodów,</li>
              <li>
                prowadzenie odrębnych ewidencji dla celów podatku od towarów i
                usług (VAT),
              </li>
              <li>prowadzenie wykazu środków trwałych,</li>
              <li>
                sporządzanie deklaracji podatkowych oraz zeznań podatkowych,
              </li>
              <li>
                sporządzanie informacji podsumowujących o transakcjach
                wewnątrzwspólnotowych,
              </li>
              <li>
                ustalanie należnych zaliczek w zakresie zryczałtowanego podatku
                dochodowego,
              </li>
              <li>
                pomoc w ustaleniu optymalnej formy opodatkowania
                przychodów/dochodów,
              </li>
              <li>reprezentowanie klientów w kontaktach z US i ZUS.</li>
            </ul>
          </li>
          <li>
            ewidencji dla celów podatku od towarów i usług (VAT)
            <ul>
              <li>
                prowadzenie ewidencji zakupów towarów i usług, w tym transakcji
                wewnątrzwspólnotowych,
              </li>
              <li>
                prowadzenie ewidencji sprzedaży towarów i usług, w tym
                transakcji wewnątrzwspólnotowych,
              </li>
              <li>
                sporządzanie deklaracji podatkowych (VAT-7 lub VAT-7K, VAT-UE),
              </li>
              <li>sporządzanie sprawozdań INTRASTAT.</li>
            </ul>
          </li>
          <li>
            ewidencji środków trwałych oraz wartości niematerialnych i prawnych
            <ul>
              <li>
                zakładanie ewidencji środków trwałych, prowadzenie ewidencji
              </li>
              <li>
                środków trwałych, weryfikacja zapisów w ewidencji środków
                trwałych ze stanem faktycznym.
              </li>
            </ul>
          </li>
          <li>
            ewidencji wyposażenia
            <ul>
              <li>zakładanie ewidencji wyposażenia, prowadzenie ewidencji</li>
              <li>
                wyposażenia, weryfikacja zapisów w ewidencji wyposażenia ze
                stanem faktycznym.
              </li>
            </ul>
          </li>
          <li>sporządzanie deklaracji i zeznań podatkowych,</li>
          <li>obliczanie zaliczek na podatek dochodowy (PIT, CIT).</li>
        </ul>
      </>
    ),
  },
  {
    id: "service_02",
    title: "Kadry i Płace",
    icon: <IoCalendarOutline />,
    description:
      "Korzyści z powierzenia obsługi kadrowo - płacowej naszej Firmy to nie tylko lepsza organizacja pracy w firmie ale przede wszystkim znacznie mniejsze ryzyko popełnienia błędów i ponoszenia kosztów z tego tytułu. Listy płac, deklaracje rozliczeniowe do ZUS, roczne informacje o dochodach (PIT-11), deklaracje PFRON",
    content: (
      <>
        <h4>
          Oferta naszej Kancelarii skierowana jest przede wszystkim do
          Przedsiębiorców, którzy mają świadomość, że obsługa kadr i płac musi
          być prowadzona zgodnie z obowiązującymi przepisami prawa, z
          zachowaniem wymaganych terminów oraz należytą starannością, a
          jednocześnie chcą obniżyć koszty związane z wypełnieniem tych
          obowiązków.
          <br />
          <br />W ramach obsługi płacowo-kadrowej mieszczą się m.in.:
        </h4>
        <ul>
          <li>sporządzanie list płac,</li>
          <li>sporządzanie umów zleceń i o dzieło,</li>
          <li>
            prowadzenie rozliczeń podatku dochodowego od osób fizycznych oraz
            składek na ubezpieczenie społeczne i zdrowotne zatrudnionych osób,
          </li>
          <li>
            sporządzanie niezbędnych informacji i deklaracji (PIT-4R, PIT-11,
            IFT-1, PIT-40),
          </li>
          <li>prowadzenie kart przychodów pracowników,</li>
          <li>zakładanie akt osobowych,</li>
          <li>przygotowywanie umów o pracę oraz aneksów,</li>
          <li>sporządzanie świadectw pracy,</li>
          <li>ustalanie uprawnień urlopowych,</li>
          <li>wystawianie zaświadczeń o zatrudnieniu i wynagrodzeniu,</li>
          <li>
            wspieranie w trakcie kontroli prowadzonych przez ZUS oraz PIP.
          </li>
        </ul>
      </>
    ),
  },
  {
    id: "service_03",
    title: "Doradztwo Podatkowe",
    icon: <BsPersonExclamation />,
    description:
      " Opinie, audyty, wyjaśnienia i porady z zakresu doradztwa podatkowego stanowią trzon działalności naszej firmy. Z dużym skutkiem, od wielu lat, pomagamy naszym klientom optymalizować zobowiązania podatkowe. Sporządzanie informacji, deklaracji i zeznań podatkowych, doradztwo w zakresie PIT, CIT, VAT,",
    content: (
      <>
        <h4>
          Korzystanie z naszej oferty w zakresie doradztwa podatkowego zwalnia
          naszych klientów z konieczności stałego śledzenia zmian przepisów,
          jednocześnie umożliwiając im stały dostęp do aktualnych informacji.
          <br />
          <br />
          Usługi doradztwa podatkowego obejmują:
        </h4>
        <ul>
          <li>udzielanie opinii, wyjaśnień i porad podatkowych,</li>
          <li>sporządzanie informacji, deklaracji i zeznań podatkowych,</li>
          <li>doradztwo w zakresie PIT, CIT, VAT,</li>
          <li>
            doradztwo w zakresie podatku od spadków i darowizn, podatku od
            czynności cywilnoprawnych (PCC),
          </li>
          <li>pomoc przy rozliczaniu dochodów ze sprzedaży nieruchomości,</li>
          <li>
            audyt podatkowy obejmujący: (analizę obciążeń podatkowych, kontrolę
            prawidłowości rozliczeń podatkowych, wskazanie rozwiązań
            zmierzających do usunięcia błędów oraz sposobów uniknięcia ich w
            przyszłości, propozycje działań umożliwiających minimalizację
            obciążeń podatkowych oraz negatywnych skutków finansowych
            stwierdzonych nieprawidłowości),
          </li>
          <li>pomoc w optymalizacji zobowiązań podatkowych,</li>
          <li>
            reprezentowanie klienta przed organami podatkowymi, organami
            skarbowymi i sądami administracyjnymi.
          </li>
        </ul>
      </>
    ),
  },
];
