import { css, styled } from "styled-components";

const Services = styled.ul`
  display: flex;
  align-items: stretch;
  margin: 0 auto;

  @media screen and (max-width: 991px) {
    flex-direction: column;
    gap: 16px;
    flex-wrap: wrap;
    padding: 32px 8px;
  }

  @media screen and (min-width: 992px) {
    gap: 32px;
    padding: 64px 8px;
    max-width: 900px;
  }
`;

const Button = styled.button`
  border: 1px solid gray;
  border-radius: 0;
  padding: 8px 16px;
  background-color: transparent;
  color: #444444;
  cursor: pointer;
  transition: color, background-color ease-in 0.2s;

  &:hover {
    background-color: #444444;
    color: #eeeeee;
  }
`;

const Ul = styled.ul`
  list-style: none;
  padding: 0;
  display: flex;
  gap: 24px;
  list-style: none;

  @media screen and (max-width: 480px) {
    flex-direction: column;

    li,
    button {
      width: 100%;
    }
  }

  @media screen and (min-width: 481px) and (max-width: 991px) {
    justify-content: center;
  }

  @media screen and (min-width: 992px) {
    flex-direction: column;
    min-width: 200px;

    li,
    button {
      width: 100%;
    }
  }
`;

const Paragraph = styled.p`
  color: ${({ theme }) => theme.color.secondary.light};
  margin: 0;
  grid-area: top;

  @media screen and (max-width: 991px) {
  }

  @media screen and (min-width: 992px) {
    flex: 3;
  }
`;

const Content = styled.div`
  height: 100%;
  grid-area: bottom;
  overflow: hidden;

  ul,
  ol {
    list-style: none;
    padding: 0;
  }

  li::before {
    content: "✓";
    display: inline-block;
    margin-right: 8px;
  }
`;

const Service = styled.li<{ active: boolean }>`
  list-style: none;
  height: auto;
  width: auto;
  background-color: ${({ theme }) => theme.color.primary.light};
  color: ${({ theme }) => theme.color.secondary.main};
  display: grid;
  grid-template-rows: auto 0fr;
  place-content: start;
  transition: grid-template-rows .4s ease-in;
  grid-template-areas: 'top' 'bottom';

  ${({ active }) =>
    active
      ? css`
          grid-template-rows: auto 1fr;
        `
      : ""}

  @media screen and (max-width: 991px) {
    padding: 16px;
  }

  @media screen and (min-width: 992px) {
    padding: 0 40px;
  }
`;

const ReadMoreBtn = styled.button`
  margin-top: 16px;
  display: block;
  border: none;
  border-bottom: 1px solid black;
  padding: 4px 8px;
  background-color: transparent;
  cursor: pointer;
`;

export { Services, Ul, Button, Service, Paragraph, Content, ReadMoreBtn };
