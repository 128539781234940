import React from "react";
import * as Styled from "./Hero.styles";

const Hero = () => {
  return (
    <Styled.Hero>
      <Styled.Title>BIURO RACHUNKOWE Z&nbsp;TRADYCJAMI</Styled.Title>
      <p>Jesteśmy z Wami od 1999 roku</p>
    </Styled.Hero>
  );
};

export default Hero;
