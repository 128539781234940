export const dark = {
  color: {
    primary: {
      light: "#454545",
      main: "#343434",
      dark: "#121212",
    },
    secondary: {
      light: "#fefefe",
      main: "#f2f2f2",
      dark: "#efefef",
    },
    bg: "#232323",
  },
};
