import React, { ReactNode } from "react";
import * as Styled from "./Footer.styled";

const IconnedTitle = ({
  icon,
  title,
  color,
  url
}: {
  icon: ReactNode;
  title: ReactNode;
  color: string;
  url?: string
}) => {
  return (
    <Styled.IconnedTitle>
      <Styled.IconWrapper style={{ backgroundColor: color }}>
        {icon}
      </Styled.IconWrapper>
      {url ? <a href={url}>{title}</a> : title}
    </Styled.IconnedTitle>
  );
};

export default IconnedTitle;
