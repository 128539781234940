import React from "react";
import * as Styled from './View3d.styles'

const View3d = () => {
  return (
    <Styled.View3d>
      <iframe
        title="widok 3d biura rachunkowego MB"
        style={{ width: "100%", height: "500px" }}
        src="https://www.google.com/maps/embed?pb=!4v1624882737167!6m8!1m7!1sCAoSLEFGMVFpcE9DSHFLMlJWRi1PUDRrZXJkTHNKVUhkNVg3MlZDQld3LWl2ZmpN!2m2!1d52.40121708604519!2d17.07661638235554!3f275.70063334260857!4f-5.091935219437914!5f0.4000000000000002"
      ></iframe>
    </Styled.View3d>
  );
};

export default View3d;
