import { Helmet } from "react-helmet";

const Seo = () => {
  return (
    <Helmet>
      <meta charSet="utf-8" />
      <title>Biuro rachunkowe MB</title>
      <meta name="description" content="Biuro Rachunkowe MB" />
      <meta property="og:title" content="Biuro rachunkowe MB" />
      <meta property="og:description " content="Biuro rachunkowe MB" />
      <meta property="og:locale" content="pl_PL" />
    </Helmet>
  );
};

export default Seo;
