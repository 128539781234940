import { styled } from "styled-components";

const Places = styled.section`
  max-width: 900px;
  margin: 0 auto;

  @media screen and (max-width: 991px) {
    padding: 16px;
  }

  @media screen and (min-width: 992px) {
    padding: 64px 16px;
  }
`;

export { Places };
