import React, { useEffect, useState } from "react";
import * as Styled from "./Header.styled";

const Header = () => {
  const [isTop, setIsTop] = useState(true)

  useEffect(() => {
    const handleScrollChange = () => {
      const value = window.scrollY < 200

      setIsTop(value)
    };

    if (window) {
      window.addEventListener("scroll", handleScrollChange);
    }

    return () => {
      window.removeEventListener("scroll", handleScrollChange);
    };
  }, []);

  return (
    <Styled.HeaderWrapper isTop={isTop}>
      <Styled.Header>
        <img src="/new-images/logo.webp" />
      </Styled.Header>
    </Styled.HeaderWrapper>
  );
};

export default Header;
