import React from "react";
import * as Styled from "./Offer.styled";
import { offer } from "./Offer.mock";

const Offer = () => {
  return (
    <Styled.Offers>
      {offer.map((offerEl, idx) => (
        <Styled.Offer key={idx}>
          <img src={`/new-images/${offerEl.image}`} alt="Grafika" />
          <Styled.Content>
            <h2>{offerEl.title}</h2>
            <Styled.List>
              {offerEl.items.map((item, itemIdx) => (
                <React.Fragment key={itemIdx}>{item}</React.Fragment>
              ))}
            </Styled.List>
          </Styled.Content>
        </Styled.Offer>
      ))}
    </Styled.Offers>
  );
};

export default Offer;
