import { styled } from "styled-components";

const Hero = styled.div`
  background: linear-gradient(0deg, rgba(0, 0, 0, 0.7), rgba(0, 0, 0, 0.7)),
    url(/new-images/hero.jpg);
  background-size: cover;
  height: 100vh;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  color: ${({ theme }) => theme.color.primary.main};
  text-align: center;

  @media screen and (max-width: 991px) {
    padding: 16px;
  }
`;

const Title = styled.h1`
  @media screen and (min-width: 992px) {
    font-size: 3rem;
  }
`;

export { Hero, Title };
