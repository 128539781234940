import React from "react";
import * as Styled from "./Places.styled";

const Places = () => {
  return <Styled.Places>
    <h1>Główna siedziba MB Biuro Rachunkowe znajduje się w Swarzędzu, jednak&nbsp;usługi świadczyny dla firm w całej Polsce.</h1>

    <p>Świadczyny usługę w zakresie odbioru dokumentacji z siedziby Zleceniodawcy</p>

    <p>Odbieramy dokumenty w miastach: <i>Swarzędz, Poznań, Luboń, Kostrzyn, Kórnik, Murowana Goślina</i> <br></br>oraz gmin: <i>Suchy Las, Tarnowo Podgórne, Rokietnica, Komorniki, Czerwonak, Kleszczewo</i></p>
  </Styled.Places>;
};

export default Places;
